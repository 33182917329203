:root {
  --primary: #08b4c0;
  --primary-dark: #222b2c;
  --success: #52a400;
  --warning: #ffe448;
  --warning-light: rgba(255, 140, 33, 0.1);
  --danger: #eb5757;
  --danger-light: rgba(235, 87, 87, 0.1);
  --white: #ffffff;
  --black: #231f20;
  --black-light: #2b2b2b;

  --gray-100: #f5f5f5;
  --gray-200: #e8e8e8;
  --gray-300: #d9d9d9;
  --gray-400: #bfbfbf;
  --gray-500: #999999;
  --gray-600: #595959;
  --gray-700: #434343;
  --gray-800: #363333;

  --font-semi-bold: 400;
  --font-bold: 700;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--black);
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--gray-200);
}

pre {
  color: var(--white);
}

input,
select,
textarea {
  font-size: medium;
  padding: 0.8rem;
  margin-top: 0.15rem;
  background: var(--white);
  border: 1px solid var(--gray-500);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="checkbox"] {
  appearance: none;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  margin: 6px;
  padding: 0;
  background-color: transparent;
  transition: outline 0.2s;
  cursor: pointer;
}

input[type="checkbox"]:active {
  outline: none;
}

input[type="checkbox"]:checked {
  background-size: cover;
  padding: 2px;
}

input[type="checkbox"]:not(:disabled):checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 32 32" xml:space="preserve"><path style="fill: %2308b4c0" d="M11.941,28.877l-11.941-11.942l5.695-5.696l6.246,6.246l14.364-14.364L32,8.818"/></svg>');
}

input[type="file"] {
  display: none;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::placeholder,
::-ms-input-placeholder {
  font-weight: 400;
  color: var(--gray-400);
  opacity: 1;
}

select {
  appearance: none;
}

textarea {
  resize: none;
  height: 8rem;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--black);
  outline: none;
}

input.error,
select.error,
textarea.error {
  border: 1px solid var(--danger);
}

button {
  font-weight: lighter;
  font-size: 0.8rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.25rem;
  min-width: fit-content;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  background: var(--primary);
  color: var(--white);
}

button:hover:not(:disabled) {
  filter: brightness(0.8);
}

button:active:not(:disabled) {
  filter: brightness(0.6);
}

button:disabled {
  cursor: not-allowed;
  background: var(--gray-200);
  color: var(--gray-400);
}

.btn-success:disabled {
  cursor: not-allowed;
  background: var(--success);
  opacity: 0.5;
}

table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
  overflow-x: auto;
}

th,
td {
  min-width: 100px;
  max-width: 100px;
  font-size: 0.9rem;
  block-size: fit-content;
  overflow: hidden;
  text-align: center;
  padding: 1.3rem 0.65rem;
  border: 0.5px solid var(--gray-600);
}

.card,
th,
table th:first-child {
  background-color: var(--primary-dark);
  border: 0.5px solid var(--gray-600);
}

td {
  font-weight: lighter;
  font-size: 0.9rem;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: var(--primary);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--gray-100);
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

::-webkit-scrollbar-thumb:active {
  background: var(--primary-dark);
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(128, 128, 128, 0.4) !important;
}

.loader {
  border: 2.5px solid var(--gray-300);
  border-top: 2.5px solid var(--primary);
  border-radius: 100%;
  width: 22px;
  height: 22px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dashboard-container {
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  padding-bottom: 20rem;
  background-color: var(--black);
  color: var(--white);
}

.dashboard-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dashboard-content-header h1 {
  font-size: 1.65rem;
  line-height: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: unset;
  }
}

.shadow {
  box-shadow: 0px 4px 8px 0px rgba(26, 26, 26, 0.3);
}

.btn-sm {
  font-size: 0.75rem;
  padding: 0.48rem 1.15rem;
  border-radius: 0.5rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  border-radius: 0.25rem;
}

.btn-success {
  background-color: var(--success);
  color: var(--white);
}

.btn-dark {
  background-color: var(--black);
  border: 1px solid var(--gray-600);
  color: var(--white);
}

.btn-dark-light {
  background-color: var(--gray-600);
  color: var(--white);
}

.btn-white {
  background-color: var(--white);
  color: var(--primary);
}

.btn-danger {
  background-color: var(--danger);
  color: var(--white);
}

.btn-danger-white {
  background-color: var(--white);
  color: var(--danger);
}

.btn-clear {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}

.btn-clear:disabled {
  opacity: 0.75;
  cursor: not-allowed;
  background-color: transparent;
}

.txt-bold {
  font-weight: bold !important;
}

.txt-primary {
  color: var(--primary);
}

.txt-success {
  color: var(--success);
}

.txt-danger {
  color: var(--danger);
}

.txt-lighter {
  font-weight: lighter;
}

.txt-sm {
  font-size: 0.8rem;
}

.txt-center {
  text-align: center;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
}

.version {
  position: fixed;
  font-size: 10px;
  color: var(--gray-600);
  right: 8px;
  bottom: 4px;
  opacity: 0.8;
}

.react-datepicker__input-container input {
  border-radius: 8px !important;
}
